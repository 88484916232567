<template>
	<div id="app">
		<router-view v-slot="{ Component }">
			<keep-alive>
				<component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name" />
			</keep-alive>
			<component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.name" />
		</router-view>
	</div>
</template>
<script>
export default {
	data() {
		return {};
	},
	created() {
	},

	methods: {},
};
</script>
<style lang="less">
@import './assets/css/reset.css';
@import './assets/css/common.css';
#app {
	width: 100%;
	height: 100%;
}
</style>
