export default {
	/** 接口请求的基础路径 */
	baseUrl: {
		development: 'http://api.jinliwangluo.com/api/h5-client',
		test: 'http://api.yingbowl.com/api/h5-client',
		production: 'https://api.pzds.com/api/h5-client',
	},
	mobileUrl: {
		development: 'http://m7.jinliwangluo.com',
		test: 'http://m7.yingbowl.com',
		production: 'https://m7.pzds.com',
	},
	OpenInstallKey: {
		development: 'pqbnjl',
		test: 'pqbnjl',
		production: 'qs4ha4',
	}
};
