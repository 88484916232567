import md5 from 'blueimp-md5'

/* eslint-disable node/prefer-global/process */
export default function (data, method) {
	const Random = random_string(6, '0123456789')

	/**
	 * 签名跳过
	 */
	let signSkip = false

	const basic = {
		'Content-Type': 'application/json',
		'PZVersion': '1.0.3',
		'PZPlatform': 'h5',
		'PZOs': window.navigator.userAgent,
		'PZTimestamp': Date.now(),
		'Random': Random,
		'signSkip': signSkip
	}



	const sign = getSign(data, method, basic)

	basic.sign = sign
	return basic
}

function random_string(len, chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678') {
	len = len || 32
	const maxPos = chars.length
	let pwd = ''
	for (let i = 0; i < len; i++) pwd += chars.charAt(Math.floor(Math.random() * maxPos))

	return pwd
}

function customEncodeURIComponent(str) {
	return encodeURIComponent(str).replace(/[*~!()']/g, c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`)
}

function getSign(data, method, basic) {
	let result = ''
	if (method === 'GET') {
		const tempData = {
			...data,
			PZTimestamp: basic.PZTimestamp,
			Random: basic.Random
		}
		const keysArr = Object.keys(tempData).sort()
		keysArr.forEach(key => {
			const item = tempData[key]
			const type = Object.prototype.toString.call(item)
			if (type === '[object Null]' || type === '[object Undefined]' || type === '[object Array]' || type === '[object Object]')
				return
			result += `${key}=${customEncodeURIComponent(item)}&`
		})
		result += `accessKey=3qXyB7uf`
	}
	else if (method === 'POST')
		result = `PZTimestamp=${basic.PZTimestamp}&Random=${basic.Random}&${0x7FFFFFFF}=${customEncodeURIComponent(JSON.stringify(data))}&accessKey=3qXyB7uf`


	return md5(result)
}
