import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Readme from '../views/Readme.vue'

const routes = [
  {
    path: '/:id',
    name: 'home',
    meta: { keepAlive: true },
    component: HomeView
  },
  {
    path: '/readme',
    name: 'readme',
    component: Readme
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
