export default {
  computed: {
    // 本地icon加载
    localIcon() {
      return url => {
        return `assets/img/${url}`
      }
    }
  }
}
