/** **   request.js   ****/
// 导入axios
import axios from 'axios';
import config from '@/config/index';
import { showLoadingToast, showFailToast, closeToast } from 'vant';
import setHeader from './sign'

const baseUrl = config.baseUrl[process.env.NODE_ENV];
// 1. 创建新的axios实例，
const service = axios.create({
	// 公共接口--这里注意后面会讲
	baseURL: baseUrl,
	// 超时时间 单位是ms，这里设置了3s的超时时间
	timeout: 30 * 1000,
});
// 2.请求拦截器
service.interceptors.request.use(
	config => {
		const data = config.data;
		// 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
		config.data = JSON.stringify(data); // 数据转化,也可以使用qs转换
		config.headers = {
			'Content-Type': 'application/json', // 配置请求头
		};
		
        const method = (config.method || "GET").toUpperCase();
		
		const myHeaders = setHeader(data, method);
        Object.assign(config.headers, myHeaders);

		return config;
	},
	error => {
		closeToast();
		Promise.reject(error);
	}
);

// 3.响应拦截器
service.interceptors.response.use(
	response => {
		closeToast();
		return response.data;
	},
	error => {
		/** *** 接收到异常响应的处理开始 *****/
		console.log(error);
		closeToast();
		showLoadingToast({
			message: 'Connection error, please try again',
			duration: 2000,
		});
		return Promise.resolve(error.response);
	}
);
// 4.导入文件
export default service;
