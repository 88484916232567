<template>
  <div>
    <component :is="componentId" @login="login" :code="channelCode"></component>
  </div>
</template>

<script>
import pageType1 from "components/pageType1";
import pageType2 from "components/pageType2";
import pageType3 from "components/pageType3";
import pageType4 from "components/pageType4";
import pageGG from "components/pageGG";
import pageMM from "components/pageMM";
import testApi from "@/api/test";
import { showSuccessToast, showFailToast } from "vant";
import config from "@/config/index";

const targetList = [
  // {
  //   component: pageType1,
  //   key: ["yuzi"],
  // },
  // {
  //   component: pageType2,
  //   key: ["xiaomayi"],
  // },
  // {
  // 	component: pageType3,
  // 	key: ['lili'],
  // },
  {
    component: pageType4,
    key: ["ahao"],
  },
  {
    component: pageGG,
    key: [
      // "xiaohang",
      // "yuliang",
      "ajing",
      "pzdsapp",
      // "huaming",
      // "shiyou",
      // "dongyang",
      // "xiaohuache",
      // "awei",
      // "aawei",
      // "shequn66",
    ], // 'xujinan'
  },
  {
    component: pageMM,
    key: [
      // "miaomiao",
      // "anan",
      // "gungun",
      // "wangzhexiaoduzi",
      // "shequn33",
      // "shequn99",
      "xiaoman",
      "yueyue",
      "xiaoyang",
      "xiaoyu",
      "luzi",
      "xiaokui",
      "jingjing",
      "qiqi",
    ],
  },
];
export default {
  computed: {
    componentId() {
      const target = targetList.find((item) =>
        item.key.includes(this.channelCode)
      );
      if (target) return target.component;
      else return null;
    },
  },
  data() {
    return {
      channelCode: null,
      // query: {},
      // pathname: '',
    };
  },
  created() {
    this.channelCode = this.$route.query.channelCode;
  },
  mounted() {
    this.loadScript();
  },
  methods: {
    login(data) {
      testApi
        .login({ action: { ...data, channelCode: this.channelCode } })
        .then((res) => {
          if (res.success) {
            showSuccessToast("注册成功，请在盼之官网重新登录");
            setTimeout(() => {
              const mobileUrl = config.mobileUrl[process.env.NODE_ENV];
              window.open(mobileUrl);
            }, 500);
          } else {
            showFailToast(res.info);
          }
        });
    },
    loadScript() {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "//web.cdn.openinstall.io/openinstall.js";
      s.addEventListener(
        "load",
        () => {
          var data = OpenInstall.parseUrlParams();
          new OpenInstall(
            {
              appKey: config.OpenInstallKey[process.env.NODE_ENV],
              channelCode: this.channelCode,
              //appkey参数配置,需要自行替换对应的appkey
              onready: function () {
                console.log("OpenInstall Ready");
                //     var m = this, button = document.getElementById("downloadButton");
                //     m.schemeWakeup();
                //     button.onclick = function() {
                //         m.wakeupOrInstall();
                //         return false;
                //     }
              },
            },
            data
          );
        },
        false
      );
      document.head.appendChild(s);
    },
  },
};
</script>

<style lang="less" scoped></style>
